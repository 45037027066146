<style lang="less" scoped>
  @import "../../../assets/css/variables";
</style>
<style>
  .text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/service/infobase/list' }">
        运维服务
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/service/infobase/list' }">
        运维知识库
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="queryInfoBase">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 200px;"
              v-model="filters.key"
              placeholder="请输入搜索关键字"
            ></el-input>
            <el-input v-show="false" style="width: 240px;"></el-input>
            <el-select
              v-model="filters.categoryId"
              filterable
              clearable
              placeholder="请选择故障类别"
              style="width: 200px;"
            >
              <el-option
                v-for="item in orderCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="queryInfoBase">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <!-- 运维信息 -->
      <el-table
        :data="InfoBaseList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column label="序号" align="center" width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          min-width="80"
          label="标题"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="categoryName"
          label="故障类别"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          min-width="210"
          :formatter="formatContent"
        >
          <template slot-scope="scope">
            <span class="text-ellipsis">{{ formatContent(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateUserName"
          label="操作人员"
        ></el-table-column>
        <el-table-column label="更新时间" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.updateTime">
              {{ moment(scope.row.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-button
              size="small"
              class="m-l-xs"
              type="primary"
              @click="handlGoinfo(scope.$index, scope.row)"
            >
              详情
            </el-button>
            <!-- <el-button size="small" @click="handleEdit(scope.$index, scope.row)">修改</el-button> -->
            <el-button
              :disabled="!editPermission"
              type="danger"
              class="m-l-xs"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button type="info" @click="goToCreate">新增知识库</el-button>
        <el-button
          :disabled="sels.length === 0 || !editPermission"
          type="danger"
          @click="batchRemove"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 50]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
    <!-- <el-dialog :title="isEdit?'修改故障':'新增故障'" :close-on-click-modal="false" size="small" v-model="infoBaseVisible">
      <el-form ref="infoForm" :rules="rules" :model="InfoBase" label-width="80px">
        <el-form-item label="标题:" prop="title">
          <el-input v-model="InfoBase.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="图片:" prop="fileList">
          <el-upload
            :on-change="fileChange"
            multiple
            action="https://jsonplaceholder.typicode.com/posts/"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="类别:" prop="categoryId">
          <el-select v-model="orderCategory" filterable clearable placeholder="请选择故障类别" style="width: 200px;">
            <el-option
              v-for="item in orderCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容:" prop="content">
          <el-input type="textarea" :rows="5" :maxlength="200" v-model="InfoBase.content" placeholder="请输入故障内容"></el-input>
        </el-form-item>
        <el-form-item label="描述:" prop="remarks">
          <el-input v-model="InfoBase.remarks"
                    placeholder="请输入故障的描述"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -20px">
        <el-button @click="infoBaseVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitInfoBase">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
  import moment from "moment";
  import {
    getKnowledgeBase,
    createKnowledgeBase,
    updateKnowledgeBase,
    deleteKnowledgeBase,
  } from "../../../services/knowledgeBase";
  import { getAllfaultLevels } from "../../../services/orderCategory";
  import { Notification } from "element-ui";
  import { PAGE_SIZE, IMAGE_PATH } from "../../../config";
  import { mapGetters, mapActions } from "vuex";
  import uploadDefaultImg from "../../../assets/images/upload-defualt.png";

  export default {
    data() {
      const valiName = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入故障名称"));
        } else if (value.length > 30 || value.length < 2) {
          callback(new Error("故障名称只能由2-30位字符组成!"));
        } else {
          callback();
        }
      };
      return {
        editPermission: true,
        moment,
        orderCategory: "",
        fileList: [],
        isEdit: false, //修改信息 false 新增 true 修改
        loaded: false, //第一次加载状态
        PAGE_SIZE,
        IMAGE_PATH,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        choosedRowIndex: -1, //修改、删除操作的时候当前选中的行的下标
        total: 1,
        orderCategoryList: [],
        InfoBaseList: [],
        filters: {
          key: "",
          categoryId: "",
        },
        InfoBase: {
          id: "",
          title: "",
          image: "",
          content: "",
          remarks: "",
          categoryId: "",
        },
        rules: {
          key: [{ validator: valiName, trigger: "blur" }],
          remarks: [
            {
              require: false,
              min: 4,
              max: 200,
              message: "描述长度在4到200个字符",
              trigger: "change",
            },
          ],
        },
        sels: [],
        listLoading: false, //列表加载完毕状态
        infoBaseVisible: false, //新增设备类型的view
      };
    },
    methods: {
      /**
       * 判断编辑权限
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "KnowledgeBase:edit";
        });
      },
      formatContent(row) {
        let content = row.content.replace(/<\/?.+?>/g, "");
        content = content.replace(/&nbsp;/g, "");
        return content;
      },
      fileChange(file, fileList) {
        let imgList = [];
        fileList.forEach((item) => {
          imgList.push(item.url);
        });
        this.InfoBase.image = imgList.join(",");
      },
      /**
       * 获取所有故障类别
       * @type {String}
       */
      async getAllOrderCategory() {
        const responseData = await getAllfaultLevels("", {
          size: 999,
          page: 1,
        });
        if (responseData && responseData.errorCode === 0 && responseData.data) {
          this.orderCategoryList = responseData.data.content;
        }
      },

      /**
       * 提交更新信息
       */
      async submitInfoBase() {
        this.$refs.infoForm.validate((valid) => {
          if (valid) {
            this.updateInfoBase();
          }
        });
      },
      /**
       * 设备详情
       * @type {[type]}
       */
      handlGoinfo(index, row) {
        window.router.push({ path: "info", query: { id: row.id } });
      },
      goToCreate() {
        window.router.push({ path: "addInfo" });
      },

      /**
       * 创建和更新故障
       */
      async updateInfoBase() {
        console.log(this.fileList);
        let imgList = this.fileList.forEach((item) => {
          imgList.push(item.url);
        });
        console.log(imgList);
        this.InfoBase["category.id"] = this.orderCategory;
        this.InfoBase["updateUser.id"] = this.user.id;
        delete this.InfoBase.categoryId;
        let responseData = null;
        if (this.isEdit) {
          responseData = await updateKnowledgeBase(this.InfoBase);
        } else {
          delete this.InfoBase.id;
          responseData = await createKnowledgeBase(this.InfoBase);
        }
        if (responseData && responseData.errorCode === 0 && responseData.data) {
          this.infoBaseVisible = false;
          if (!this.isEdit) {
            this.InfoBaseList.push(responseData.data);
            this.total++;
            if (this.total % this.PAGE_SIZE === 1) {
              this.pager.page++;
            }
          } else {
            this.InfoBaseList.splice(
              this.choosedRowIndex,
              1,
              responseData.data
            );
          }
          Notification.success({
            title: "成功",
            message: this.isEdit ? "修改成功" : "新增成功",
          });
        } else {
          Notification.error({
            title: "错误",
            message: responseData.errorMessage,
          });
        }
      },

      /**
       *修改故障信息
       */
      handleEdit(index, type) {
        this.choosedRowIndex = index;
        this.InfoBase = Object.assign({}, type);
        this.isEdit = true;
        this.infoBaseVisible = true;
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //  this.pager.page = page;
        //  this.queryUsers();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "list", query: { page: page } });
          this.queryInfoBase();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryInfoBase();
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 单条故障的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            deleteKnowledgeBase(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryInfoBase();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            deleteKnowledgeBase(ids).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryInfoBase();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * 获取所有的一级故障
       */
      async queryInfoBase() {
        this.listLoading = true;
        const responseData = await getKnowledgeBase(
          this.filters.categoryId,
          this.filters.key,
          this.pager
        );
        if (responseData.errorCode === 0) {
          this.InfoBaseList =
            (responseData && responseData.data && responseData.data.content) ||
            [];
          this.total = responseData.data.totalElements;
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },
    async created() {
      this.getAllOrderCategory();
      this.queryInfoBase();
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
    },
    mounted() {
      this.queryInfoBase();
      this.checkEditPermission();
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    watch: {
      infoBaseVisible(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === false) {
            this.$refs.infoForm.resetFields();
            this.isEdit = false;
          }
        }
      },
      fileList(newVal) {},
    },
  };
</script>
